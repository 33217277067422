<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"><router-link to="/">Dashboard</router-link> / Integrations</h1>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <v-layout row wrap class="d-flex justify-space-between align-center">
          <v-flex xs8 md6 lg4>
            <v-text-field
              single-line
              outlined
              v-model="searchText"
              label="Type to search.."
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-flex>
          <v-flex xs4 md4 lg4 class="d-flex justify-end">
            <v-btn class="primary black--text" x-large @click="addIntegration">Add Integration</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">
                  Integration Channel
                </th>
                <th class="text-left uppercase primary--text">Created</th>
                <th class="text-left uppercase primary--text">Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filteredIntegrations" :key="item._id">
                <td>{{ index + 1 }}</td>
                <td class="primary--text fs-12 fw-bold">{{ item.channel }}</td>
                <td>{{ item.createdAt | moment("dddd, MMMM Do YYYY") }}</td>
                <td>{{ item.updatedAt | moment("dddd, MMMM Do YYYY") }}</td>
                <td class="primary--text" @click="generateApiSecret(item._id)">
                  <u>Generate API Secret</u>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          API Secret
        </v-card-title>
        <v-card-text>
          <p class="mt-5">
            Please save or download the API secret key for your integration. You
            will not be able to view the token again.
          </p>
          <div
            class="pa-3"
            style="
              border: 1px solid #424242;
              border-radius: 5px;
              margin-top: 10px;
            "
          >
            <p class="accent--text">{{ token }}</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copy"> Copy </v-btn>
          <v-btn color="primary" text> Download </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import Axios from "axios";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      integrations: [],
      searchText: "",
      dialog: false,
      token: "",
      integration: {},
    };
  },
  mounted() {
    this.fetchIntegrations();
  },
  computed: {
    filteredIntegrations() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.integrations.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        return this.integrations;
      }
    },
  },
  methods: {
    async fetchIntegrations() {
      this.$store.dispatch("SET_PROGRESS",true)
      let url = BASE_URL + "/integration";
      let { data } = await Axios.get(url);
      this.integrations = data;
      this.$store.dispatch("SET_PROGRESS",false)
    },
    async generateApiSecret(id) {
      let url = BASE_URL + "/integration/generate-access-token/" + id;
      let { data } = await Axios.post(url);
      this.token = data.token;
      this.integration = data.integration;
      this.dialog = true;
    },
    copy() {
      navigator.clipboard
        .writeText(this.token)
        .then(() => {
            alert("hi")
          console.log("Text copied to clipboard");
        })
        .catch((error) => {
            alert("error")
          console.error("Failed to copy text: ", error);
        });
    },
    addIntegration(){
        this.$router.push('/integration/add')
    },
   
    // viewAccount(id) {
    //   this.$router.push({ name: "ViewAccount", params: { id: id } });
    // },
    // addUser() {
    //   this.$router.push("/add-account");
    // },
  },
};
</script>

<style scoped>
.fs-12 {
  font-size: 12pt !important;
}
</style>